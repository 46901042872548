import React from 'react'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import ContentBlock from '../components/ContentBlock'
import Img from 'gatsby-image'

const TeachingPage = props => {

  const classImages = props.data.allImagesJson.edges[0].node.childTeachingJson.classes.reduce((acc, curr) => {
    acc.push(curr.childImageSharp.fluid)
    return acc
  }, [])
  const workshopImages = props.data.allImagesJson.edges[0].node.childTeachingJson.workshops.reduce((acc, curr) => {
    acc.push(curr.childImageSharp.fluid)
    return acc
  }, [])


  return (
    <Layout>
      <ContentBlock content={['teaching.class.subtitle']} specialClass='header' />
      <ContentBlock content={['teaching.class.1', 'teaching.class.2', 'teaching.class.3']} />

      <div class="photoGridWrap">
        <div className={(function(t) {
          switch(t.length) {
            case 1:
              return "photoRow singleColumn";
            case 2:
              return "photoRow doubleColumn";
            default:
              return "photoRow multiColumn";
          }
        })(classImages)}>
        {classImages.map((i) => (
          <div className="photoColumn">
            <Img fluid={i} />
          </div>
        ))}
        </div>
      </div>



      <ContentBlock content={['teaching.workshop.subtitle']} specialClass='header' />
      <ContentBlock content={['teaching.workshop.1']} />

      <div class="photoGridWrap">
        <div className={(function(t) {
          switch(t.length) {
            case 1:
              return "photoRow singleColumn";
            case 2:
              return "photoRow doubleColumn";
            default:
              return "photoRow multiColumn";
          }
        })(workshopImages)}>
        {workshopImages.map((i) => (
          <div className="photoColumn">
            <Img fluid={i} />
          </div>
        ))}
        </div>
      </div>

    </Layout>
  )
}

export const TeachingImageQuery = graphql`
  query TeachingImageQuery {
    allImagesJson: allFile(
      filter: { relativePath: { eq: "grid/teaching/images.json" } }
    ) {
      edges {
        node {
          childTeachingJson {
            classes {
              childImageSharp {
                fluid(maxWidth: 2280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            workshops {
              childImageSharp {
                fluid(maxWidth: 2280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withIntl(TeachingPage)
